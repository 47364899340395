import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./sidebar.css";
import SearchByManufacturer from "../searchByCategory/index";

const Sidebar = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(`${serverUrl}/get_categories.php`, requestOptions);
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="sidebar">
      <SearchByManufacturer />
      <div className="categories">
        {categories.map((category) => (
          <div key={category.category_id} className="category">
            <div className="category-title">
              {category.category_name}{" "}
              <span className="badge bg-success">{category.percentage_margin}%</span>
            </div>
            <div className="sub-categories">
              <div className="sub-category-title">
                <Link
                  to={`/products/${category.category_id}/all/all?category_name=${encodeURIComponent(
                    category.category_name
                  )}&sub_category_name=all&manufacturer_name=all`}
                >
                  All {category.category_name}
                </Link>
              </div>

              {category.subcategories.map((subCategory) => (
                <div key={subCategory.sub_category_id} className="sub-category">
                  <div className="sub-category-title">
                    {subCategory.sub_category_name}{" "}
                    <span className="badge bg-success">{subCategory.percentage_margin}%</span>
                  </div>
                  <div className="manufacturers">
                    <div className="manufacturer">
                      <Link
                        to={`/products/${category.category_id}/${
                          subCategory.sub_category_id
                        }/all?category_name=${encodeURIComponent(
                          category.category_name
                        )}&sub_category_name=${encodeURIComponent(
                          subCategory.sub_category_name
                        )}&manufacturer_name=all`}
                      >
                        All {subCategory.sub_category_name} Products
                      </Link>
                    </div>
                    {subCategory.manufacturers.map((manufacturer) => (
                      <div key={manufacturer.manufacturer_id} className="manufacturer">
                        <Link
                          to={`/products/${category.category_id}/${subCategory.sub_category_id}/${
                            manufacturer.manufacturer_id
                          }?category_name=${encodeURIComponent(
                            category.category_name
                          )}&sub_category_name=${encodeURIComponent(
                            subCategory.sub_category_name
                          )}&manufacturer_name=${encodeURIComponent(
                            manufacturer.manufacturer_name
                          )}`}
                        >
                          {manufacturer.manufacturer_name}{" "}
                          <span className="badge bg-success">
                            {manufacturer.percentage_margin}%
                          </span>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
