import React, { useState, useEffect } from "react";
import Home from "../header/index";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../Loading/loading";

const Product = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [data, setData] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${serverUrl}/get_product_details.php?id=${id}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          toast.error(data.error_msg);
          return;
        }
        toast.success("Product fetched successfully");
        setData(data.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  if (!data) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Home />
      <div className="d-flex">
        <div className="container w-75">
          <h1 className="text-center m-5">Product Details</h1>
          <div className="card mb-4 p-4">
            <h2>{data.item_description}</h2>
            <img
              src={`http://www.bti-usa.com/images/pictures/${data.image_path}`}
              alt={data.item_description}
              className="img-fluid my-3"
            />
            <p>
              <strong>
                <a
                  href={`https://www.bti-usa.com/public/item/${id}?track=true`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  View on BTI
                </a>
              </strong>
            </p>
            <p>
              <strong>Total Available:</strong> {data.available}
            </p>
            <p>
              <strong>Available Santa Fe:</strong> {data.available_santa_fe}
            </p>
            <p>
              <strong>Available Reno:</strong> {data.available_reno}
            </p>
            <p>
              <strong>Current Price:</strong> ${data.current_price}
            </p>
            <p>
              <strong>Your Price:</strong> ${data.your_price}
            </p>
            <p>
              <strong>MSRP:</strong> ${data.msrp}
            </p>
            <p>
              <strong>Margin:</strong> ${(data.msrp - data.your_price).toFixed(2)}
            </p>
            <p>
              <strong>Category:</strong> {data.category_name}
            </p>
            <p>
              <strong>Sub-Category:</strong> {data.sub_category_name}
            </p>
            <p>
              <strong>Manufacturer:</strong> {data.manufacturer_name}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
