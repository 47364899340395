import React from "react";
import Sidebar from "../sidebar/index";
import Home from "../header/index";
const BtiProducts = () => {
  return (
    <>
      <Home />
      <Sidebar />
    </>
  );
};

export default BtiProducts;
