import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import AuthContext from "../../context/AuthContext";
import { FaUserCircle } from "react-icons/fa";

const Header = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/");
  };
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid style={{ padding: "0px 20px" }}>
          <Navbar.Brand href="/dashboard">Garage Cycles</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="li-text">
              <Nav.Link href="/bti-products">BTI Products</Nav.Link>
              <Nav.Link href="/existing-products">Existing Products</Nav.Link>
              <Nav.Link href="/removed-products">Removed Products</Nav.Link>
              <Nav.Link href="/sale-products">Sale/Closeout Products</Nav.Link>
              <Nav.Link href="/orders">Orders</Nav.Link>
              <Nav.Link href="/merchant-products">Merchant Products</Nav.Link>
              {/* <Nav.Link href="/temp-deleted">Temp Deleted</Nav.Link> */}
            </Nav>

            <Nav className="ms-auto d-none d-lg-block">
              <Dropdown
                align="end"
                style={{ border: "none", boxShadow: "none", borderColor: "white" }}
              >
                <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                  <FaUserCircle size={24} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/change-password">Change Password</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>

            <Nav className="mx-3 d-lg-none">
              <Nav.Link href="/change-password">Change Password</Nav.Link>
              <Button variant="outline-danger" onClick={handleLogout}>
                Logout
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Header;
