import React, { useMemo, useState, useEffect } from "react";
import DoughnutChart from "./doughnutChart";
import BarChart from "./barChart";
import Header from "../header";
import "./index.css";
import { Link } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

const Dashboard = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [totalProducts, setTotalProducts] = useState("");
  const [deletedVariants, setDeletedVariants] = useState("");
  const [notUploaded, setNotUploaded] = useState("");
  const [uploadedProducts, setUploadedProducts] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [categoryProductData, setCategoryProductData] = useState([]);
  const [data, setData] = useState([]);
  const [countOnSale, setCountOnSale] = useState("");
  const [countCloseOut, setCountCloseOut] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${serverUrl}/get_stats.php`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setTotalProducts(data.data.count_total);
        setDeletedVariants(data.data.count_deleted_variants);
        setNotUploaded(data.data.count_not_uploaded);
        setUploadedProducts(data.data.count_uploaded_variants);
        setData(data.data.products);
        setCountOnSale(data.data.count_on_sale);
        setCountCloseOut(data.data.count_close_out);
        const categories = data.data.categories.map((category) => ({
          name: category.category_name,
          percentageMargin: parseFloat(category.percentage_margin),
        }));
        setCategoryData(categories);

        const productCounts = data.data.categories.map((category) => ({
          name: category.category_name,
          productCount: parseInt(category.total_products, 10),
        }));
        setCategoryProductData(productCounts);
      })
      .catch((error) => {
        console.error("Departments Error:", error);
      });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        Cell: ({ cell }) => {
          const id = cell.row.original.id;
          return (
            <a
              href={`/product?id=${id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              {id}
            </a>
          );
        },
      },
      {
        accessorKey: "margin",
        header: "Margin",
        Cell: ({ cell }) => <div className="h6">${cell.row.original?.margin}</div>,
      },
      {
        accessorKey: "your_price",
        header: "Your Price",
        Cell: ({ cell }) => <div className="h6">${cell.row.original?.your_price}</div>,
      },
      {
        accessorKey: "msrp",
        header: "MSRP",
        Cell: ({ cell }) => <div className="h6">${cell.row.original?.msrp}</div>,
      },
      {
        accessorKey: "item_description",
        header: "Description",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.item_description}</div>,
      },
      {
        accessorKey: "category_name",
        header: "Category",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.category_name}</div>,
      },
      {
        accessorKey: "sub_category_name",
        header: "Subcategory",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.sub_category_name}</div>,
      },
      {
        accessorKey: "manufacturer_name",
        header: "Manufacturer",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.manufacturer_name}</div>,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enablePagination: true,
    enableStickyHeader: true,
    getRowId: (row) => row.id,
    initialState: {
      rowPinning: {
        top: data > 0 ? [data[0].id] : [],
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "650px",
      },
    },
  });

  return (
    <>
      <Header />
      <div className="container text-center">
        <div className="row g-4 mt-4">
          <div className="col-md-7 mt-4 h-100">
            <div className="card column">
              <div className="card-body">
                <p>Top 10 products with highest margin</p>
                <MaterialReactTable table={table} />
              </div>
            </div>
          </div>
          <div className="col-md-5 mt-4 h-100">
            <div className="row">
              <div className="col-md-12">
                <div className="card column">
                  <div className="card-body">
                    <DoughnutChart categoryProductData={categoryProductData} />
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="card column">
                  <div className="card-body">
                    <h5 className="card-title main-heading">On Sale Products</h5>
                    <p className="card-text content">
                      <Link to={`/sale-products?type=Sale`} style={{ textDecoration: "underline" }}>
                        {countOnSale}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="card column">
                  <div className="card-body">
                    <h5 className="card-title main-heading">Close out Products</h5>
                    <p className="card-text content">
                      <Link
                        to={`/sale-products?type=Closeout`}
                        style={{ textDecoration: "underline" }}
                      >
                        {countCloseOut}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-4 mt-4">
          <div className="col-md-6 mt-4">
            <div className="card column">
              <div className="card-body">
                <h5 className="card-title main-heading">Total Products</h5>
                <p className="card-text content">{totalProducts}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="card column">
              <div className="card-body">
                <h5 className="card-title main-heading">Deleted Variants</h5>
                <p className="card-text content">
                  <Link to={`/removed-products`} style={{ textDecoration: "underline" }}>
                    {deletedVariants}
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="card column">
              <div className="card-body">
                <h5 className="card-title main-heading">Uploaded Products</h5>
                <p className="card-text content">
                  <Link to={`/existing-products`} style={{ textDecoration: "underline" }}>
                    {uploadedProducts}
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="card column">
              <div className="card-body">
                <h5 className="card-title main-heading">Not Uploaded Products</h5>
                <p className="card-text content">
                  <Link to={`/bti-products`} style={{ textDecoration: "underline" }}>
                    {notUploaded}
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="card column">
              <div className="card-body">
                <BarChart categoryData={categoryData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
