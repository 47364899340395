import React, { useState, useEffect } from "react";
import Header from "../header/index";
import { toast } from "react-toastify";
import ProductCard from "./card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingScreen from "../Loading/loading";
import { useLocation } from "react-router-dom";

const SaleProducts = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const [filterOption, setFilterOption] = useState(type ? type : "All");

  const fetchData = () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${serverUrl}/get_sale_closeout_products.php`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setLoading(false);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        if (data.error) {
          toast.error(data.error_msg);
          return;
        }
        toast.success("Products fetched successfully");
        setData(data.data);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
    setCurrentPage(1);
  };

  const filteredData = data.filter((product) => {
    if (filterOption === "All") return true;
    if (filterOption === "Sale") return product.is_on_sale === "1";
    if (filterOption === "Closeout") return product.is_on_sale === "0";
    return true;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <>
      <Header />
      <div className="d-flex">
        <div className="container">
          <h1 className="text-center m-5">Sale/Closeout Products</h1>
          {loading ? (
            <LoadingScreen />
          ) : (
            <>
              <FormControl variant="outlined" sx={{ mb: 4, minWidth: 120 }}>
                <InputLabel id="filter-label">Filter</InputLabel>
                <Select
                  labelId="filter-label"
                  value={filterOption}
                  onChange={handleFilterChange}
                  label="Filter"
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Sale">On Sale</MenuItem>
                  <MenuItem value="Closeout">Closeout</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" sx={{ mb: 4, minWidth: 120 }}>
                <InputLabel id="items-per-page-label">Items per page</InputLabel>
                <Select
                  labelId="items-per-page-label"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  label="Items per page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {currentItems.map((product) => (
                    <Grid item xs={12} lg={4} md={6} key={product.id}>
                      <ProductCard data={product} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={handlePageChange}
                sx={{ mt: 4, display: "flex", justifyContent: "center", marginBottom: "20px" }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default SaleProducts;
