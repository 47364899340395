import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ProductCard = ({ data }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 345, display: "flex", flexDirection: "column", height: "100%" }}>
      <CardHeader title={data?.item_description} subheader={data?.id} />
      <CardMedia
        component="img"
        height="200"
        image={`http://www.bti-usa.com/images/pictures${data.image_path}`}
        alt={data?.id}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography>
          {data.is_on_sale === "1" ? (
            <span className="badge bg-success">Sale</span>
          ) : (
            <span className="badge bg-success">Closeout</span>
          )}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {data.group_text}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          aria-label="share"
          href={`https://www.bti-usa.com/public/item/${data.id}?track=true`}
          target="_blank"
        >
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {data.is_on_sale === "1" && (
            <>
              <Typography paragraph>
                <strong>Sale Starts On</strong> {data.sale_starts_on}
              </Typography>
              <Typography paragraph>
                <strong>Sale Ends On</strong> {data.sales_ends_on}
              </Typography>
            </>
          )}
          <Typography paragraph>
            <strong>Current Price</strong> ${data.current_price}
          </Typography>
          <Typography paragraph>
            <strong>Your Price</strong> ${data.your_price}
          </Typography>
          <Typography paragraph>
            <strong>MSRP</strong> ${data.msrp}
          </Typography>
          <Typography paragraph>
            <strong>MAP</strong> ${data.map}
          </Typography>
          <Typography paragraph>
            <strong>Regular Price</strong> ${data.regular_price}
          </Typography>
          <Typography paragraph>
            <strong>Margin</strong> ${parseFloat(data.msrp - data.your_price).toFixed(2)}
          </Typography>
          <Typography paragraph>
            <strong>Oversize Surcharge</strong> ${data.oversize_surcharge}
          </Typography>
          <Typography paragraph>
            <strong>Total in Stock:</strong> {data.available}
          </Typography>
          <Typography paragraph>
            <strong>Available at Reno:</strong> {data.available_reno}
          </Typography>
          <Typography paragraph>
            <strong>Available at Santa Fe:</strong> {data.available_santa_fe}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ProductCard;
