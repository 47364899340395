import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const getRandomColors = (numOfColors) => {
  const colors = [];
  for (let i = 0; i < numOfColors; i++) {
    const randomColor = `hsl(${Math.floor(Math.random() * 360)}, 100%, 75%)`;
    colors.push(randomColor);
  }
  return colors;
};

const DoughnutChart = ({ categoryProductData }) => {
  const labels = categoryProductData.map((category) => category.name);
  const dataPoints = categoryProductData.map((category) => category.productCount);

  const backgroundColors = getRandomColors(categoryProductData.length);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Number of Products",
        data: dataPoints,
        backgroundColor: backgroundColors,
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
      title: {
        display: true,
        text: "Number of Products by Category",
      },
    },
  };

  return <Doughnut data={data} options={options} />;
};

export default DoughnutChart;
