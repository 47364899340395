import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useParams } from "react-router-dom";
import "../categoryProducts/category-products.css";
import Header from "../header/index";
import Sidebar from "../sidebar/index";
import { toast } from "react-toastify";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation } from "react-router-dom";

const ManufacturerProducts = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category_name = queryParams.get("category_name");
  const sub_category_name = queryParams.get("sub_category_name");
  const manufacturer_name = queryParams.get("manufacturer_name");
  const shopifyUrl = process.env.REACT_APP_SHOPIFY_URL;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const shopifyToken = process.env.REACT_APP_SHOPIFY_API_KEY;
  const { manufacturer_id, category_id, subcategory_id } = useParams();
  const [data, setData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let updateMyDb = [];

  useEffect(() => {
    fetchData();
  }, [category_id, subcategory_id, manufacturer_id]);

  const fetchData = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      const response = await fetch(
        `${serverUrl}/get_manufacturer_products.php?manufacturer_id=${manufacturer_id}&category_id=${category_id}&sub_category_id=${subcategory_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.error) {
        toast.error(data.error_msg);
        return;
      }
      toast.success("Products fetched successfully");
      const groupedProducts = data.data.reduce((acc, product) => {
        const {
          group_id,
          group_description,
          group_text,
          manufacturer_name,
          category_name,
          ...rest
        } = product;
        let group = acc.find((g) => g.group_id === group_id);

        if (!group) {
          group = {
            group_id,
            group_description,
            group_text,
            manufacturer_name,
            category_name,
            variants: [],
          };
          acc.push(group);
        }

        group.variants.push(rest);

        return acc;
      }, []);
      setData(groupedProducts);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "images",
        header: "Images",
        Cell: ({ cell }) => {
          const variants = cell.row.original.variants;
          const [open, setOpen] = useState(false);

          const handleOpen = () => setOpen(true);
          const handleClose = () => setOpen(false);

          const images = variants.map((variant) => ({
            original: `http://www.bti-usa.com/images/pictures${variant.image_path}`,
            thumbnail: `http://www.bti-usa.com/images/pictures${variant.image_path}`,
            description: variant.id,
            originalHeight: "380px",
          }));

          return (
            <>
              <img
                src={images[0].thumbnail}
                alt="variant"
                style={{ width: "100px", height: "100px", cursor: "pointer" }}
                onClick={handleOpen}
              />
              {images.length > 1 && <span> +{images.length - 1}</span>}

              <Modal open={open} onClose={handleClose}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "80%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    outline: "none",
                  }}
                >
                  <IconButton
                    onClick={handleClose}
                    sx={{
                      alignSelf: "flex-end",
                      mb: 1,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <ImageGallery
                    items={images}
                    showFullscreenButton={false}
                    showPlayButton={false}
                  />
                </Box>
              </Modal>
            </>
          );
        },
      },
      {
        id: "percentage_margin",
        header: "Percentage Margin",
        accessorFn: (row) => {
          let sumMsrp = 0;
          let sumYourPrice = 0;
          row.variants.forEach((variant) => {
            sumMsrp += parseFloat(variant.msrp);
            sumYourPrice += parseFloat(variant.your_price);
          });
          return parseFloat(((sumMsrp - sumYourPrice) / sumMsrp) * 100).toFixed(2);
        },
      },
      {
        accessorKey: "group_id",
        header: "Group ID",
      },
      {
        accessorKey: "category_name",
        header: "Category Name",
      },
      {
        accessorKey: "manufacturer_name",
        header: "Manufacturer Name",
      },
      {
        accessorKey: "group_description",
        header: "Group Description",
      },
      {
        id: "variants",
        header: "Variants",
        accessorFn: (row) => {
          const variants = row.variants;
          return (
            <>
              Total Variants: {variants.length}
              <br />
              {variants.map((variant, index) => (
                <React.Fragment key={variant.id}>
                  {index > 0 && ", "}
                  <a
                    href={`https://www.bti-usa.com/public/item/${variant.id}?track=true`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "underline" }}
                  >
                    {variant.id}
                  </a>
                </React.Fragment>
              ))}
            </>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enablePagination: true,
    enableRowSelection: true,
    enableStickyHeader: true,
    getRowId: (row) => row.group_id,
    initialState: {
      rowPinning: {
        top: data.length > 0 ? [data[0].group_id] : [],
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "400px",
      },
    },
  });

  useEffect(() => {
    const selectedRowIds = table.getState().rowSelection;
    const selectedIdsArray = Object.keys(selectedRowIds);
    const selectedRowsData = data.filter((row) => selectedIdsArray.includes(row.group_id));
    setSelectedRowData(selectedRowsData);
  }, [table, table.getState().rowSelection, data]);

  const uploadProducts = async () => {
    updateMyDb = [];
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("X-Shopify-Access-Token", `${shopifyToken}`);
    myHeaders.append("Content-Type", "application/json");

    for (const product of selectedRowData) {
      try {
        const result = await postProductToShopify(product, myHeaders);
        await delay(2000);
        await updateVariants(result, product, myHeaders);
      } catch (error) {
        toast.error(error.message);
      }
    }

    // uncomment when testing completed or deploying and change status to active from draft
    await delay(2000);
    await updateMyDBWithShopify();
    setIsLoading(false);
    toast.success("Products uploaded to Shopify");
  };

  const descriptionToUl = (description) => {
    const items = description.split("\\n-");
    let htmlString = "<ul>";
    items.forEach((item) => {
      htmlString += `<li>${item.replace("-", "").trim()}</li>`;
    });
    htmlString += "</ul>";
    return htmlString;
  };

  const postProductToShopify = async (product, myHeaders) => {
    const variants = product.variants.map((variant) => ({
      title: variant.item_description,
      price: variant.msrp != 0 ? variant.msrp : (variant.your_price / 0.6).toFixed(2),
      cost: variant.your_price,
      sku: variant.id,
      image: {
        src: `http://www.bti-usa.com/images/pictures${variant.image_path}`,
      },
      inventory_policy: "continue",
      fulfillment_service: "manual",
      inventory_management: "shopify",
      option1: variant.item_description,
      barcode: variant.upc || variant.ean,
      metafields: [
        {
          namespace: "custom",
          key: "map_pricing",
          value: variant.map,
          type: "string",
        },
        {
          namespace: "custom",
          key: "bulk_item_fee",
          value: variant.oversize_surcharge,
          type: "string",
        },
        {
          namespace: "custom",
          key: "is_on_sale",
          value: variant.is_on_sale === "0" ? false : true,
          type: "boolean",
        },
      ],
    }));

    const productImages = product.variants.map((variant) => ({
      src: `http://www.bti-usa.com/images/pictures${variant.image_path}`,
    }));

    const productOptions = product.variants.map((option) => option.item_description);

    const shopifyProduct = {
      product: {
        title: product.group_description.replaceAll("\\n", " "),
        body_html: "<p>" + descriptionToUl(product.group_text) + "</p>",
        vendor: product.manufacturer_name,
        product_type: product.category_name,
        status: "active",
        metafields: [
          {
            namespace: "custom",
            key: "is_bti",
            value: true,
            type: "boolean",
          },
        ],
        variants: variants,
        options: [
          {
            name: "description",
            position: 1,
            values: productOptions,
          },
        ],
        images: productImages,
      },
    };

    const rawBodyShopifyProduct = JSON.stringify(shopifyProduct);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawBodyShopifyProduct,
      redirect: "follow",
    };

    const response = await fetch(
      `/proxy.php?url=${encodeURIComponent(shopifyUrl + "/products.json")}`,
      requestOptions
    );
    // const response = await fetch(`${shopifyUrl}/products.json`, requestOptions);
    if (!response.ok) {
      toast.error(`Failed to upload product: ${response.status} - ${response.statusText}`);
    }
    return await response.json();
  };

  const updateVariants = async (result, product, myHeaders) => {
    for (const [index, variant] of result.product.variants.entries()) {
      try {
        updateMyDb.push({
          variant_id: variant.id,
          product_id: variant.product_id,
          inventory_item_id: variant.inventory_item_id,
          sku: variant.sku,
        });

        await updateVariantImage(result.product.images[index].id, variant, myHeaders);
        await delay(2000);
        await updateInventoryLevels(product, variant, myHeaders);
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const updateVariantImage = async (imageId, variant, myHeaders) => {
    const requestBodyUpdateVariantImages = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        variant: {
          id: `${variant.id}`,
          image_id: `${imageId}`,
        },
      }),
    };

    const response = await fetch(
      `/proxy.php?url=${encodeURIComponent(
        shopifyUrl + "/products/" + variant.product_id + "/variants/" + variant.id + ".json"
      )}`,
      requestBodyUpdateVariantImages
    );
    // const response = await fetch(
    //   `${shopifyUrl}/products/${variant.product_id}/variants/${variant.id}.json`,
    //   requestBodyUpdateVariantImages
    // );
    if (!response.ok) {
      throw new Error(
        `Failed to update variant images: ${response.status} - ${response.statusText}`
      );
    }
    return await response.json();
  };

  const updateInventoryLevels = async (product, variant, myHeaders) => {
    const availReno = product.variants.find((item) => item.id === variant.sku)?.available_reno || 0;

    if (availReno > 0) {
      const locationBodyReno = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          inventory_item_id: variant.inventory_item_id,
          location_id: 97295237407,
          available: availReno,
        }),
      };

      const responseReno = await fetch(
        `/proxy.php?url=${encodeURIComponent(shopifyUrl + "/inventory_levels/set.json")}`,
        locationBodyReno
      );
      // const responseReno = await fetch(`${shopifyUrl}/inventory_levels/set.json`, locationBodyReno);
      if (!responseReno.ok) {
        toast.error(
          `Failed to update inventory levels for Reno: ${responseReno.status} - ${responseReno.statusText}`
        );
      }
      await delay(2000);
    }

    const availSantaFe =
      product.variants.find((item) => item.id === variant.sku)?.available_santa_fe || 0;

    if (availSantaFe > 0) {
      const locationBodySantaFe = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          inventory_item_id: variant.inventory_item_id,
          location_id: 97295204639,
          available: availSantaFe,
        }),
      };

      const responseSantaFe = await fetch(
        `/proxy.php?url=${encodeURIComponent(shopifyUrl + "/inventory_levels/set.json")}`,
        locationBodySantaFe
      );
      // const responseSantaFe = await fetch(
      //   `${shopifyUrl}/inventory_levels/set.json`,
      //   locationBodySantaFe
      // );
      if (!responseSantaFe.ok) {
        toast.error(
          `Failed to update inventory levels for Santa Fe: ${responseSantaFe.status} - ${responseSantaFe.statusText}`
        );
      }
      await delay(2000);
    }
  };

  const updateMyDBWithShopify = async () => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ updateMyDb }),
      redirect: "follow",
    };

    const response = await fetch(`${serverUrl}/update_my_db.php`, requestOptions);
    if (!response.ok) {
      toast.error(`Failed to update DB: ${response.status} - ${response.statusText}`);
    }
    return await response.json();
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const removeVariant = (variantId) => {
    const updatedSelectedRowData = selectedRowData.map((product) => ({
      ...product,
      variants: product.variants.filter((variant) => variant.id !== variantId),
    }));
    setSelectedRowData(updatedSelectedRowData);
  };

  return (
    <>
      <Header />
      <div className="d-flex">
        <Sidebar />
        <div className="container w-75">
          <h1 className="text-center m-5">BTI Products</h1>
          <div>
            <a href={`/dashboard`} style={{ textDecoration: "underline", color: "#3498db" }}>
              <HomeIcon />{" "}
            </a>
            {manufacturer_name != "all" && (
              <a
                href={`/manufacturer-products/${manufacturer_id}/all/all?category_name=all&sub_category_name=all&manufacturer_name=${encodeURIComponent(
                  manufacturer_name
                )}`}
                style={{ textDecoration: "underline", color: "#3498db" }}
              >
                &rarr; {manufacturer_name}{" "}
              </a>
            )}
            {category_name != "all" && (
              <a
                href={`/manufacturer-products/${manufacturer_id}/${category_id}/all?category_name=${encodeURIComponent(
                  category_name
                )}&sub_category_name=all&manufacturer_name=${encodeURIComponent(
                  manufacturer_name
                )}`}
                style={{ textDecoration: "underline", color: "#3498db" }}
              >
                &rarr; {category_name}{" "}
              </a>
            )}
            {sub_category_name != "all" && (
              <a
                href={`/manufacturer-products/${manufacturer_id}/${category_id}/${subcategory_id}?category_name=${encodeURIComponent(
                  category_name
                )}&sub_category_name=${encodeURIComponent(
                  sub_category_name
                )}&manufacturer_name=${encodeURIComponent(manufacturer_name)}`}
                style={{ textDecoration: "underline", color: "#3498db" }}
              >
                &rarr; {sub_category_name}{" "}
              </a>
            )}
          </div>
          <MaterialReactTable table={table} />
          {selectedRowData.length > 0 && (
            <div>
              <div className="row">
                <h1 className="text-center m-5">Selected Products</h1>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={uploadProducts}
                  disabled={isLoading}
                >
                  {isLoading ? "Uploading..." : "Upload Products"}
                </button>
              </div>
              {selectedRowData.map((product) =>
                product.variants.map((row) => (
                  <div key={row.id} className="product-container">
                    <div className="product-image">
                      <img
                        src={`http://www.bti-usa.com/images/pictures` + row.image_path}
                        alt={row.item_description}
                      />
                    </div>
                    <div className="product-details">
                      <h1 className="product-title">{row.item_description}</h1>
                      <p className="product-specs">
                        <strong>Product ID:</strong> {row.id}
                      </p>
                      <p className="product-specs">
                        <strong>Current Price</strong> ${row.current_price}
                      </p>
                      <p className="product-specs">
                        <strong>Your Price</strong> ${row.your_price}
                      </p>
                      <p className="product-specs">
                        <strong>MSRP</strong> ${row.msrp}
                      </p>
                      <p className="product-specs">
                        <strong>MAP</strong> ${row.map}
                      </p>
                      <p className="product-specs">
                        <strong>Regular Price</strong> ${row.regular_price}
                      </p>
                      <p className="product-specs">
                        <strong>Margin</strong> ${parseFloat(row.msrp - row.your_price).toFixed(2)}
                      </p>
                      <p className="product-specs">
                        <strong>Is on sale</strong> {row.is_on_sale === "0" ? "False" : "True"}
                      </p>
                      <p className="product-specs">
                        <strong>Close out</strong> {row.is_on_closeout === "0" ? "False" : "True"}
                      </p>
                      <p className="product-specs">
                        <strong>Oversize Surcharge</strong> ${row.oversize_surcharge}
                      </p>
                      <p className="product-specs">
                        <strong>Total in Stock:</strong> {row.available}
                      </p>
                      <p className="product-specs">
                        <strong>Available at Reno:</strong> {row.available_reno}
                      </p>
                      <p className="product-specs">
                        <strong>Available at Santa Fe:</strong> {row.available_santa_fe}
                      </p>
                    </div>
                    <div className="remove-btn">
                      <button
                        className="btn btn-danger btn-lg"
                        onClick={() => removeVariant(row.id)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ManufacturerProducts;
