import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ categoryData }) => {
  const labels = categoryData.map((category) => category.name);
  const dataPoints = categoryData.map((category) => category.percentageMargin);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Percentage Margin (%)",
        data: dataPoints,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Percentage Margin by Category",
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
