import React, { useEffect, useState } from "react";
import Home from "../header/index";
import "./styleproducts.css";
import { MaterialReactTable } from "material-react-table";
import { gapi } from "gapi-script";
import { toast } from "react-toastify";
import LoadingScreen from "../Loading/loading";

const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const SCOPE = process.env.REACT_APP_SCOPE;
const GOOGEL_API_URL = process.env.REACT_APP_GOOGLE_API;
const SERVER_API_URL = process.env.REACT_APP_SERVER_URL;

const MerchantProducts = () => {
  const [tokenId, setTokenId] = useState(null);
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckToken, setIsCheckToken] = useState(true);
  const [storeShopifyData, setStoreShopifyData] = useState("");

  useEffect(() => {
    fetch(`${SERVER_API_URL}/get_uploaded_products.php`)
      .then((resp) => resp.json())
      .then((data) => {
        setStoreShopifyData(data.data);
        if (localStorage.getItem("tokenID")) {
          setTokenId(localStorage.getItem("tokenID"));
          setIsLoading(true);
          getProducts();
        }
        setIsCheckToken(false);
      })
      .catch((error) => console.log(error));
  }, [tokenId]);

  const handleClick = () => {
    const loadGapiScript = () => {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/api.js";
      script.onload = () => {
        initializeGapiClient();
      };
      document.body.appendChild(script);
    };
    const initializeGapiClient = () => {
      gapi.load("client:auth2", initClient);
    };
    const initClient = () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          scope: SCOPE,
        })
        .then(() => {
          const authInstance = gapi.auth2.getAuthInstance();
          authInstance.signIn().then(onSuccess).catch(onFailure);
        })
        .catch((error) => {
          console.error("Error loading GAPI client: ", error);
        });
    };
    loadGapiScript();
  };

  const getProducts = () => {
    if (!tokenId) {
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${tokenId}`);
    const raw = JSON.stringify({
      query:
        "SELECT product_view.id, product_view.title, product_view.brand,  product_view.price_micros, product_view.currency_code, price_insights.suggested_price_micros,  price_insights.suggested_price_currency_code FROM PriceInsightsProductView",
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${GOOGEL_API_URL}`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("tokenID");
          toast.error("Token expired. Please Reload to create a new session.");
          setTokenId(null);
          setIsLoading(false);
          return;
        } else {
          return response.json();
        }
      })
      .then((result) => {
        if (result && result.results) {
          setProductData(result.results);
        } else {
          setProductData([]);
        }
      })
      .catch((error) => {
        toast.error(error);
        setIsLoading(false);
      });
  };

  const onSuccess = (googleUser) => {
    const authResponse = googleUser.getAuthResponse();
    const token = authResponse.access_token;
    localStorage.setItem("tokenID", token);
    setTokenId(token);
    setIsLoading(false);
    getProducts();
  };

  const onFailure = (error) => {
    setIsLoading(true);
    toast.error(error);
  };

  const columns = [
    {
      header: "Title",
      accessorKey: "productView.title",
      size: 300,
    },
    {
      header: "Brand",
      accessorKey: "productView.brand",
      size: 150,
    },
    {
      header: "Price (USD)",
      accessorKey: "productView.priceMicros",
      Cell: ({ cell }) => <div>${(cell.getValue() / 1000000).toFixed(2)}</div>,
      size: 150,
    },
    {
      header: "Suggested Price (USD)",
      accessorKey: "priceInsights.suggestedPriceMicros",
      Cell: ({ cell }) => <div>${(cell.getValue() / 1000000).toFixed(2)}</div>,
      size: 250,
    },
    {
      header: "Difference",
      accessorKey: "difference",
      Cell: ({ cell }) => {
        let diff = (
          (cell.row.original.productView.priceMicros -
            cell.row.original.priceInsights.suggestedPriceMicros) /
          1000000
        ).toFixed(2);
        if (diff > 0) {
          return <span className="badge bg-danger">${diff}</span>;
        } else {
          return <span className="badge bg-success">${diff}</span>;
        }
      },
    },
    {
      header: "Available Reno",
      accessorFn: (row) => storeShopifyData[row.productView.id]?.available_reno,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return <div>{value !== undefined ? `${value}` : "N/A"}</div>;
      },
    },
    {
      header: "Available Santa Fe",
      accessorFn: (row) => storeShopifyData[row.productView.id]?.available_santa_fe,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return <div>{value !== undefined && value !== 0 ? `${value}` : "N/A"}</div>;
      },
    },
    {
      header: "Your Price",
      accessorFn: (row) => storeShopifyData[row.productView.id]?.your_price,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return <div>{value !== undefined ? `$${value}` : "N/A"}</div>;
      },
    },
    {
      header: "Price Margin",
      accessorFn: (row) => {
        const yourPrice = storeShopifyData[row.productView.id]?.your_price;
        const mrspPrice = storeShopifyData[row.productView.id]?.msrp;
        if (yourPrice !== undefined && mrspPrice !== undefined) {
          return mrspPrice - yourPrice;
        }
        return undefined;
      },
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return <div>{value !== undefined ? `$${Number(value).toFixed(2)}` : "N/A"}</div>;
      },
    },
  ];

  if (isCheckToken) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Home />
      {(!tokenId && !isLoading) || isLoading === false ? (
        <div className="loginButton">
          <button onClick={handleClick}>
            <span>Click to Get Products data</span>
          </button>
        </div>
      ) : isLoading ? (
        tokenId && (
          <div style={{ borderRadius: "15px" }}>
            <div className="d-flex">
              <div className="container w-75">
                <h1 className="text-center m-5">Merchant Products</h1>
                <MaterialReactTable
                  columns={columns}
                  data={productData}
                  style={{ width: "100%" }}
                  enablePagination={true}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
};
export default MerchantProducts;
