import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import Home from "../header/index";
import { toast } from "react-toastify";

const Orders = () => {
  const shopifyUrl = process.env.REACT_APP_SHOPIFY_URL;
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [nextUrl, setNextUrl] = useState(`${shopifyUrl}/orders.json`);
  const [hasFetched, setHasFetched] = useState(false);

  const fetchData = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("X-Shopify-Access-Token", "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");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(`/proxy.php?url=${encodeURIComponent(shopifyUrl + "/orders.json")}`, requestOptions)
      // fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const linkHeader = response.headers.get("Link");
        if (linkHeader) {
          const nextPageLink = parseNextPageLink(linkHeader);
          if (nextPageLink) {
            setNextUrl(`/proxy.php?url=${encodeURIComponent(nextPageLink)}`);
            // setNextUrl("http://localhost:8080/" + nextPageLink);
          } else {
            setNextUrl(null);
          }
        } else {
          setNextUrl(null);
        }
        return response.json();
      })
      .then((data) => {
        setData((prevData) => [...prevData, ...data.orders]);
      })

      .catch((error) => {
        toast.error(error.message);
      });
  };

  const parseNextPageLink = (linkHeader) => {
    const links = linkHeader.split(",");
    for (let link of links) {
      const [url, rel] = link.split(";").map((part) => part.trim());
      if (rel === 'rel="next"') {
        return url.slice(1, -1);
      }
    }
    toast.success("All Orders fetched successfully");
    return null;
  };

  useEffect(() => {
    if (nextUrl && isFetching) {
      if (!hasFetched) {
        toast.info("Please Wait, Fetching Order's....");
        setHasFetched(true);
      }

      const fetchNextPage = setTimeout(() => {
        fetchData(nextUrl);
      }, 1000);

      return () => clearTimeout(fetchNextPage);
    }
  }, [nextUrl, isFetching]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Order No.",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.name}</div>,
      },
      {
        accessorKey: "unfulfilledItems",
        header: "Unfulfilled Items",
        Cell: ({ cell }) => {
          const lineItems = cell.row.original?.line_items;
          const fulfillments = cell.row.original?.fulfillments;
          const refunds = cell.row.original?.refunds;

          const unfulfilledItems = lineItems.map((lineItem) => {
            const fulfillmentExists = fulfillments.some((fulfillment) =>
              fulfillment.line_items.some((fulfillmentItem) => fulfillmentItem.id === lineItem.id)
            );

            const refundExists = refunds.some((refund) =>
              refund.refund_line_items.some((refundItem) => refundItem.line_item_id === lineItem.id)
            );
            return {
              lineItemId: lineItem.id,
              lineItem,
              fulfillmentExists,
              refundExists,
            };
          });

          const filteredUnfulfilledItems = unfulfilledItems.filter((filterItem) => {
            return !filterItem.fulfillmentExists;
          });

          const filteredRefundItem = unfulfilledItems.filter((filterItem) => {
            return filterItem.refundExists;
          });
          const afterRemovingRefundItem = filteredUnfulfilledItems.filter(
            (item) => !filteredRefundItem.some((items) => items.lineItemId === item.lineItemId)
          );
          if (unfulfilledItems.length > 0) {
            return (
              <ul>
                {afterRemovingRefundItem.map((FinalProductData, index) => (
                  <li key={index} className="border rounded p-1 my-1">
                    {FinalProductData.lineItem.title || "Unnamed Line Item"}
                  </li>
                ))}
              </ul>
            );
          }

          return <li>No unfulfilled items</li>;
        },
        size: "500",
      },
      {
        accessorKey: "id",
        header: "Order ID",
        Cell: ({ cell }) => {
          const id = cell.row.original.id;
          return (
            <a
              href={`https://www.bti-usa.com/public/item/${id}?track=true`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {id}
            </a>
          );
        },
      },
      {
        accessorKey: "current_price",
        header: "Subtotal Price",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.current_subtotal_price}</div>,
      },
      {
        accessorKey: "your_price",
        header: "Amount",
        Cell: ({ cell }) => (
          <div className="h6">
            {cell.row.original?.current_subtotal_price_set?.shop_money.amount}
          </div>
        ),
      },
      {
        accessorKey: "first_name",
        header: "First Name",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.customer?.first_name}</div>,
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.customer?.last_name}</div>,
      },
      {
        accessorKey: "customer_address",
        header: "Customer Address",
        Cell: ({ cell }) => (
          <div className="h6">
            {cell.row.original?.customer?.default_address?.address1 || "N/A"}
          </div>
        ),
      },
      {
        accessorKey: "customer_city",
        header: "Customer City",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original?.customer?.default_address?.city}</div>
        ),
      },
      {
        accessorKey: "customer_state",
        header: "Customer State",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original.customer?.default_address?.province}</div>
        ),
      },
      {
        accessorKey: "customer_zip",
        header: "Customer Zip Code",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original.customer?.default_address?.zip}</div>
        ),
      },
      {
        accessorKey: "shipping_name",
        header: "Shipping name",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_address?.name}</div>,
      },
      {
        accessorKey: "shipping_address",
        header: "Shipping Address",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original?.shipping_address?.address1}</div>
        ),
      },
      {
        accessorKey: "shipping_city",
        header: "Shipping City",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_address?.city}</div>,
      },
      {
        accessorKey: "shipping_State",
        header: "Shipping State",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original?.shipping_address?.province}</div>
        ),
      },
      {
        accessorKey: "shipping_number",
        header: "Shipping phone number",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_address?.phone}</div>,
        size: "250",
      },
      {
        accessorKey: "shipping_zip",
        header: "Shipping Zip no",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_address?.zip}</div>,
      },
      {
        accessorKey: "shipping_line",
        header: "Shipping line",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_lines[0]?.code}</div>,
      },
      {
        accessorKey: "shipping_line discounted price",
        header: "Shipping line discounted price",
        size: 300,
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original?.shipping_lines[0]?.discounted_price}</div>
        ),
      },
      {
        accessorKey: "shipping_line price",
        header: "Shipping line price",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.shipping_lines[0]?.price}</div>,
      },
      {
        accessorKey: "shipping_line source",
        header: "Shipping line source",
        Cell: ({ cell }) => (
          <div className="h6">{cell.row.original?.shipping_lines[0]?.source}</div>
        ),
      },
      {
        accessorKey: "subtotal_price",
        header: "Subtotal Price",
        Cell: ({ cell }) => <div className="h6">{cell.row.original?.subtotal_price}</div>,
      },

      {
        accessorKey: "financial_status",
        header: "Financial Status",
        Cell: ({ cell }) => (
          <div className="h6 bg-success text-center text-white" style={{ width: "100px" }}>
            {cell.row.original?.financial_status}
          </div>
        ),
      },
      {
        accessorKey: "line_items_name",
        header: "Line Items Name",
        size: 500,
        Cell: ({ cell }) => {
          const lineItems = cell.row.original?.line_items || [];
          return (
            <ul>
              {lineItems.map((products, index) =>
                products.name ? (
                  <li key={index} className="border rounded p-1 my-1">
                    {products.name}
                  </li>
                ) : null
              )}
            </ul>
          );
        },
      },
      {
        accessorKey: "line_items_vendor",
        header: "Line Items Vendor",
        size: 300,
        Cell: ({ cell }) => {
          const lineItems = cell.row.original?.line_items || [];
          return (
            <ul>
              {lineItems.map((products, index) =>
                products.vendor ? <li key={index}>{products.vendor}</li> : null
              )}
            </ul>
          );
        },
      },
      {
        accessorKey: "variant_titles",
        header: "Variant Title",
        size: 300,
        Cell: ({ cell }) => {
          const lineItems = cell.row.original?.line_items || [];
          return (
            <ul>
              {lineItems.map((products, index) =>
                products.variant_title ? <li key={index}>{products.variant_title}</li> : null
              )}
            </ul>
          );
        },
      },
      {
        accessorKey: "sku",
        header: "Product Sku",
        size: 300,
        Cell: ({ cell }) => {
          const lineItems = cell.row.original?.line_items || [];
          return (
            <ul>
              {lineItems.map((products, index) =>
                products.sku ? <li key={index}>{products.sku}</li> : null
              )}
            </ul>
          );
        },
      },
      {
        accessorKey: "variant_ids",
        header: "Variant Id",
        size: 300,
        Cell: ({ cell }) => {
          const lineItems = cell.row.original?.line_items || [];
          return (
            <ul>
              {lineItems.map((products, index) =>
                products.variant_id ? <li key={index}>{products.variant_id}</li> : null
              )}
            </ul>
          );
        },
      },
      {
        accessorKey: "taxable",
        header: "Taxable",
        Cell: ({ cell }) => {
          const lineItems = cell.row.original?.line_items || [];
          return (
            <ul>
              {lineItems.map((products, index) =>
                products.taxable ? <li key={index}>{products.taxable ? "Yes" : "No"}</li> : null
              )}
            </ul>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enablePagination: true,
    enableStickyHeader: true,
    getRowId: (row) => row.id,
    initialState: {
      rowPinning: {
        top: data.length > 0 ? [data[0].id] : [],
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "500px",
      },
    },
  });

  return (
    <>
      <Home />
      <div className="d-flex">
        <div className="container w-75">
          <h1 className="text-center m-5">Orders</h1>
          <MaterialReactTable table={table} />
        </div>
      </div>
    </>
  );
};

export default Orders;
