import React, { useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "./context/AuthContext";
import LoadingScreen from "./components/Loading/loading";

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useContext(AuthContext);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    setIsCheckingAuth(false);
  }, [isAuthenticated]);

  if (isCheckingAuth) {
    return <LoadingScreen />;
  }

  return isAuthenticated ? children : <Navigate to="/" />;
}

export default ProtectedRoute;
