import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import Home from "../header/index";
import { toast } from "react-toastify";

const RemovedProducts = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [data, setData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${serverUrl}/get_temp_deleted_products.php`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
        toast.success("Products fetched successfully");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Product ID",
      },
      {
        accessorKey: "item_description",
        header: "Product Name",
      },
      {
        accessorKey: "current_price",
        header: "Current Price",
      },
      {
        accessorKey: "your_price",
        header: "Your Price",
      },
      {
        accessorKey: "msrp",
        header: "MSRP",
      },
      {
        accessorKey: "map",
        header: "MAP",
      },
      {
        accessorKey: "regular_price",
        header: "Regular Price",
      },
      {
        id: "margin",
        header: "Margin",
        accessorFn: (row) => {
          const { msrp, your_price } = row;
          const margin = msrp - your_price;
          return margin.toFixed(2);
        },
      },
      {
        id: "is_on_sale",
        header: "Is on sale",
        accessorFn: (row) => {
          const { is_on_sale } = row;
          return is_on_sale === "0" ? "false" : "true";
        },
      },
      {
        accessorKey: "oversize_surcharge",
        header: "Oversize Surcharge",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enablePagination: true,
    enableRowSelection: true,
    enableStickyHeader: true,
    getRowId: (row) => row.id,
    initialState: {
      rowPinning: {
        top: data.length > 0 ? [data[0].id] : [],
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "500px",
      },
    },
  });

  useEffect(() => {
    const selectedRowIds = table.getState().rowSelection;
    const selectedIdsArray = Object.keys(selectedRowIds);

    const selectedRowsData = data.filter((row) => selectedIdsArray.includes(row.id.toString()));
    setSelectedRowData(selectedRowsData);
  }, [table, table.getState().rowSelection, data]);

  return (
    <>
      <Home />
      <div className="d-flex">
        <div className="container w-75">
          <h1 className="text-center m-5">Temporary Deleted Products</h1>
          <MaterialReactTable table={table} />
          {selectedRowData.length > 0 && (
            <div>
              <div className="row">
                <h1 className="text-center m-5">Selected Products</h1>
              </div>
              {selectedRowData.map((row) => (
                <div key={row.id} className="product-container">
                  <div className="product-image">
                    <img
                      src={`http://www.bti-usa.com/images/pictures` + row.image_path}
                      alt={row.item_description}
                    />
                  </div>
                  <div className="product-details">
                    <h1 className="product-title">{row.item_description}</h1>
                    <p className="product-specs">
                      <strong>Product ID:</strong> {row.id}
                    </p>
                    <p className="product-specs">
                      <strong>Current Price</strong> ${row.current_price}
                    </p>
                    <p className="product-specs">
                      <strong>Your Price</strong> ${row.your_price}
                    </p>
                    <p className="product-specs">
                      <strong>MSRP</strong> ${row.msrp}
                    </p>
                    <p className="product-specs">
                      <strong>MAP</strong> ${row.map}
                    </p>
                    <p className="product-specs">
                      <strong>Regular Price</strong> ${row.regular_price}
                    </p>
                    <p className="product-specs">
                      <strong>Margin</strong> ${row.msrp - row.your_price}
                    </p>
                    <p className="product-specs">
                      <strong>Is on sale</strong> {row.is_on_sale === 0 ? "False" : "True"}
                    </p>
                    <p className="product-specs">
                      <strong>Oversize Surcharge</strong> ${row.oversize_surcharge}
                    </p>
                    <p className="product-specs">
                      <strong>Total in Stck:</strong> {row.available}
                    </p>
                    <p className="product-specs">
                      <strong>Available at Reno:</strong> {row.available_reno}
                    </p>
                    <p className="product-specs">
                      <strong>Available at Santa Fe:</strong> {row.available_santa_fe}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RemovedProducts;
