import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import Home from "../header/index";
import { toast } from "react-toastify";
const ExistingProducts = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [data, setData] = useState([]);

  const fetchData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(`${serverUrl}/get_existing_products.php`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          toast.error(data.error_msg);
          return;
        }
        toast.success("Products fetched successfully");
        setData(data.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Product ID",
        Cell: ({ cell }) => {
          const id = cell.row.original.id;
          return (
            <a
              href={`https://www.bti-usa.com/public/item/${id}?track=true`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              {id}
            </a>
          );
        },
      },
      {
        id: "margin",
        header: "Margin",
        accessorFn: (row) => {
          const { msrp, your_price } = row;
          const margin = msrp - your_price;
          return margin.toFixed(2);
        },
      },
      {
        accessorKey: "msrp",
        header: "MSRP",
      },
      {
        accessorKey: "your_price",
        header: "Your Price",
      },
      {
        accessorKey: "current_price",
        header: "Current Price",
      },
      {
        accessorKey: "map",
        header: "MAP",
      },
      {
        accessorKey: "regular_price",
        header: "Regular Price",
      },
      {
        id: "available",
        header: "Info",
        accessorFn: (row) => (
          <div className="h6">
            {row.available > 0 ? (
              <span className="badge bg-success">In Stock</span>
            ) : (
              <span className="badge bg-danger">Out of stock</span>
            )}
          </div>
        ),
      },
      {
        accessorKey: "item_description",
        header: "Product Name",
      },
      {
        accessorKey: "variant_id",
        header: "variant id",
      },
      {
        accessorKey: "product_id",
        header: "Product Id",
      },
      {
        id: "percentage_margin",
        header: "Percentage margin",
        accessorFn: (row) => {
          return parseFloat(
            ((parseFloat(row.msrp) - parseFloat(row.your_price)) / parseFloat(row.msrp)) * 100
          ).toFixed(2);
        },
      },
      {
        id: "is_on_sale",
        header: "Is on Sale",
        accessorFn: (row) => {
          const is_on_sale = row.is_on_sale;
          return is_on_sale == 0 ? "False" : "True";
        },
      },
      {
        accessorKey: "oversize_surcharge",
        header: "Oversize Surcharge",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enablePagination: true,
    enableStickyHeader: true,
    getRowId: (row) => row.id,
    initialState: {
      rowPinning: {
        top: data.length > 0 ? [data[0]?.id] : [],
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "500px",
      },
    },
  });

  return (
    <>
      <Home />
      <div className="d-flex">
        <div className="container w-75">
          <h1 className="text-center m-5">Existing Products</h1>
          <MaterialReactTable table={table} />
        </div>
      </div>
    </>
  );
};
export default ExistingProducts;
